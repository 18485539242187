<template>
  <UnauthenticatedForm
    :class="logo"
    :logo="logo"
  >
    <template v-slot:header>
      <h1>Welcome to {{ title }}</h1>
      <span>Complete your profile to continue</span>
    </template>
    <form>
      <BfAlert
        v-if="error"
        type="error"
        :closable="false"
        icon="warning"
      >
        {{ error }}
      </BfAlert>
      <!-- TODO: we might want to add this back if/when we allow optional fields
      <span class="required-fields">*Required Fields</span>
      -->
      <ValidationObserver v-slot="{ invalid }">
        <BfInput
          label="Email Address"
          :disabled="true"
          :value="email"
        />
        <!-- TODO: Add these fields in when their values can be pulled from the query string -->
        <!--
          <ValidationProvider
            v-slot="{ errors }"
            rules="required"
          >
            <BfInput
              v-model="firstName"
              label="First Name*"
              :error="errors[0]"
            />
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            rules="required"
          >
            <BfInput
              v-model="lastName"
              label="Last Name*"
              :error="errors[0]"
            />
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            rules="required"
          >
            <BfInput
              v-model="jobTitle"
              label="Job Title*"
              :error="errors[0]"
            />
          </ValidationProvider>
        -->
        <ValidationProvider
          v-slot="{ errors }"
          name="password"
          rules="required|password"
          mode="aggressive"
        >
          <BfPassword
            v-model="password"
            label="Enter Password"
            :error="errors[0]"
          />
        </ValidationProvider>
        <ValidationProvider
          v-slot="{ errors }"
          rules="required|sameAsPassword:@password"
        >
          <BfInput
            v-model="confirmPassword"
            label="Confirm Password"
            type="password"
            :error="errors[0]"
          />
        </ValidationProvider>
        <el-button
          type="primary"
          :disabled="invalid"
          @click="onSubmit"
        >
          Submit
        </el-button>
      </ValidationObserver>
    </form>
  </UnauthenticatedForm>
</template>

<script>
import Auth from '@aws-amplify/auth'
import BfAlert from '@/components/BfAlert/BfAlert'
import BfInput from '@/components/BfInput/BfInput'
import BfPassword from '@/components/BfPassword/BfPassword'
import UnauthenticatedForm from '@/components/UnauthenticatedForm/UnauthenticatedForm'
import { logError } from '@/utils/logging'

export default {
  name: 'NewUserOnboarding',
  components: {
    BfAlert,
    BfInput,
    BfPassword,
    UnauthenticatedForm
  },
  props: {
    logo: {
      type: String,
      default: 'blackfynn'
    },
    title: {
      type: String,
      default: 'PPMI EDC'
    },
    redirect: {
      type: String,
      default: '/'
    }
  },
  data() {
    return {
      appVersion: process.env.VUE_APP_VERSION,
      password: '',
      confirmPassword: '',
      error: ''
      /* TODO: Add these fields when their values can be pulled from the query string
      firstName: '',
      lastName: '',
      jobTitle: '',
      staffId: ''
      */
    }
  },
  computed: {
    email() {
      return this.$route.query.username
    },

    temporaryPassword() {
      return this.$route.query.temporary_password
    },

    /**
     * Look in the URL parameters for a redirect URL before setting this value to the default redirect.
     * Default redirect is read from an environment variable in the route definition.
     */
    redirectURL() {
      return this.$route.query.redirect_url || this.redirect
    }
  },
  methods: {
    onSubmit() {
      this.$store.dispatch('updateLoading', true)
      this.error = null
      Auth.signIn(this.email, this.temporaryPassword)
        .then(user => {
          if (!user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            this.error = 'Your onboarding link is invalid. Please contact support for help.'
          } else {
            Auth.completeNewPassword(
              user,
              this.password,
              {
                email: this.email
                /* TODO: Add these fields when their values can be pulled from the query string
                given_name: this.firstName,
                family_name: this.lastName,
                'custom:job_title': this.jobTitle,
                */
              }
            ).then(() => {
              window.location = this.redirectURL
            }).catch(error => {
              logError(error, 'NewUserOnboarding.vue sign in')
              this.error = 'We\'re experiencing difficulty logging you in. Please try again later.'
            })
          }
        })
        .catch(err => {
          switch (err.code) {
            case 'NotAuthorizedException':
            case 'UserNotFoundException':
              this.error = 'Your onboarding link is invalid. Please contact support for help.'
              break
            // TODO: Verify the code for an expired login link
            case 'ExpiredCodeException':
              this.error = 'Your onboarding link has expired. Please contact support for help.'
              break
            default:
              logError(err, 'NewUserOnboarding.vue sign in')
              this.error = 'We\'re experiencing difficulty logging you in. Please try again later.'
              break
          }
        })
        .finally(() =>
          this.$store.dispatch('updateLoading', false)
        )
    }
  }
}
</script>

<style lang="scss" scoped>
.required-fields {
  display: inline-block;
  padding: 1.25rem 0;
  color: $hillcock;
  @include text-style('interface', 'extra-small', 'regular')
}

header {
  margin: 0 0 1rem 1rem;

  h1 {
    margin-top: 1rem;
    margin-bottom: .5rem;
    color: $dopamine;
    @include text-style('title', 'large', 'bold')
  }

  span {
    @include text-style('title', 'medium', 'regular')
  }
}

#app .ppmi {
  width: 29rem;
  padding: 1rem 4rem;
  margin: 0 auto;

  .logo {
    width: 15rem;
  }

  h1 {
    color: $black;
  }

  .el-button {
    font-weight: 500;
    border-color: $black;
    background-color: $black;

    &:hover {
      border-color: $hillcock;
      background-color: $hillcock;
    }
  }

  .bf-alert--error {
    margin-bottom: 1rem;
  }
}
</style>
